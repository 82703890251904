@import 'src/styles/variables';

.header {
  align-items: center;
  background-color: $soot;
  display: flex;
  height: $headerheight;
  padding: 10px 15px;
  position: fixed;
  top: 0;
  width: 100%;
}

.logo {
  fill: $snow;
  font-size: 14px;
  height: 45px;
}
