@import 'colors';
@import 'breakpoints';

// fonts
$main-font: 'Open Sans', sans-serif;
$main-font-bold-weight: 600;

// line height
$lineheight: 1.2;
$lineheight-spacy: 1.4;
$lineheight-spacier: 1.6;
$lineheight-spaciest: 1.8;

// layout
$headerheight: 65px;
$border-radius: 5px;
