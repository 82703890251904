@import 'src/styles/variables';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $headerheight;
  position: relative;
  width: 100%;
}

.content {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
}
