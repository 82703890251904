$dolphin: #cfd9e2;

// light to dark
$snow: #fff;
$frost: #fcfcfc; // rgb(252,252,252)
$hoth: #f5f5f5; // rgb(245, 245, 245)
$totoro: #ededed; // rgb(237, 237, 237)
$mist: #e0e0e0; // rgb(224, 224, 224)
$fog: #d6d6d6; // rgb(214, 214, 214)
$stone: #cbced1; // rgb(203, 206, 209)
$millenium: #c5c5c5; // rgb(197, 197, 197)
$alto: #adadad; // rgb(173, 173, 172)
$tyrant: #979797;
$shadow: #6b7882;
$ray: #75787b; // rgb(117,120, 123)
$billow: #767676;
$slate: #485156;
$gris: #444546; // rgb(68,69,70)
$asphalt2: #414141; // rgb(65, 65, 65)
$asphalt: #3e3f40;
$darksaber: #394045;
$abyss: #24292e;
$soot: #121212; // rgb(18, 18, 18)
$blackhole: #000;

// caution colors
$ponyo: #ed5565;
$satsuki: #fcaa2c;
$leaf: #46b3a0;

// Blueprint colors
$noface: rgb(24, 32, 38);
$jihl: rgb(16, 107, 163);

// v2
$whale: rgb(108, 126, 142);
$down: #f8f9fa;
$midnight: #003057;

// app
$chihiro: #f7143a;
$mei: #e4f85e;
$laputa: #a5be00;
$haku: #899e00;
$ohmu: #6a7b00;
$misuto: #3690ce;
$amulet: #007cb7;
$sheeta: #427aa1;

// v3
$fio: #64041a;
$yupa: #e2b500;
$sosuke: #ffcd00;
$minto: #007b72;
$kurotawa: #02433e;
$sophie: #3d6980;
$kiki: #2b4c5d;
$ashitaka: #16486f;
$lisa: #12344e;

// v4
$pluto: #3c4e6d;
$pluto-shade: #2b3950;
$earth: #9fb543;
$earth-shade: #76882b;

// stripe
$gonta: #a0a3b5;

// curriculum
$cchdf: $kurotawa;
$whsdf: $minto;
$dsf: $ashitaka;
$efaf: $fio;
$ctmst: $yupa;
$no-curriculum: $billow;
